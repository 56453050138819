import React from "react";
import "./../styles/main.css";

const FormSubmit = () => {
  return (
    <div className="record__form submit">
      <div className="submit__title">Данные успешно отправлены</div>
    </div>
  );
};

export default FormSubmit;
