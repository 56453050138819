import React, { useEffect, useState } from "react";
import "./../styles/oleg.css";
import HeaderBanner from "./HeaderBanner";
import EnrollComponent from "./EnrollComponent";

const PriceTable = ({ services }) => {
  console.log(services);

  return (
    <div className="tableWrapperService">
      <table className="service-table">
        <tbody>
          {services[0].map((service, index) => (
            <tr key={index}>
              {services[1][index] == "" && services[2][index] == "" ? (
                <td colSpan="3" className="subtitleth">
                  {service}
                </td>
              ) : (
                <>
                  <td>{service}</td>
                  <td>{services[1][index]}</td>
                  {index == 0 ? (
                    <td> {services[2][index]}</td>
                  ) : (
                    <td>
                      {services[2][index] ? `${services[2][index]} руб` : null}
                    </td>
                  )}
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ServicePage = ({ about }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("https://vetfenix.ru/api/price_list")
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        setData(jsonData);
      })
      .catch((error) => console.error("Ошибка при запросе:", error));
  }, []);

  function RenderBlock() {
    if (about == "Анестезия и интенсивная терапия") {
      return (
        <>
          <div className="CenterBlock">
            <p>
              В своей практике мы используем принципы доказательной медицины! Мы
              не лечим гомеопатией и не назначаем «фуфломицины», которые ничего,
              кроме финансовых потерь владельца, не несут.
            </p>
            <p>
              Врач - терапевт - это, как правило, первый врач, с которым Вы
              столкнетесь, придя на прием с заболевшим животным. Врач задаст Вам
              несколько вопросов, касающихся начала, характера развития болезни,
              клинических признаков и прочего, что поможет в постановке
              диагноза. Старайтесь отвечать на его вопросы как можно более полно
              и правдиво (особенно это касается режима кормления), по
              возможности расскажите и обо всех ранее перенесенных заболеваниях
              и сданных анализах.
            </p>
          </div>
          {/* <div className='CenterBlock CenterFill'>
                    <p>А часто ли при наличии внешне здорового животного мы идем с ним в ветеринарную клинику?</p>
                    <p>Ответ очевиден: нечасто.</p>
                </div> */}

          <div className="CenterBlock">
            <p>
              Даже ранее посещенные вместе с животным страны и континенты могут
              оказаться решающим фактором в постановке диагноза. Это позволит
              точно оценить состояние животного, что вкупе в тщательным осмотром
              в ряде случаев позволит поставить диагноз и назначить правильное
              лечение сразу на приеме с минимальными дополнительными
              исследованиями. Наши питомцы не могут нам сказать, где, что у них
              болит, давно ли началось, каков характер и сила боли, что их
              беспокоит... Поэтому первый разговор с врачом так важен.
            </p>
            <p>
              При необходимости врач-терапевт назначит дополнительные
              исследования (анализы крови, мочи, кала и др.), проведение УЗИ,
              рентгена, КТ, МРТ и других исследований, которые помогут
              правильной постановке диагноза и назначению успешного лечения!
            </p>
          </div>
        </>
      );
    }
    if (about == "Родильное отделение и отделение неонатологии") {
      return (
        <>
          <div className="CenterBlock">
            <p>
              Специалисты нашего Центра одними из первых в России начали
              заниматься проблемами новорожденных собак и кошек. К настоящему
              времени накоплена большая база теоретических и практических знаний
              в области неонатологии животных.
            </p>
          </div>
          <div className="CenterBlock CenterFill">
            <p>
              В нашем Центре проводится интенсивная терапия новорожденных
              животных, имеющих проблемы с нормальным материнским
              вскармливанием.{" "}
            </p>
            {/* <p>Ответ очевиден: нечасто.</p> */}
          </div>
          <div className="CenterBlock">
            <p>
              Если малыш вялый, не сосет или плохо сосет маму, не прибавляет в
              весе, есть возможность привезти его к нам, в отделение
              неонатологии, где есть специальное оборудование для интенсивной
              терапии новорожденных (инкубаторы-брудеры) с поддержанием заданной
              влажности, температуры, ионизацией воздуха, оксигенотерапией. При
              помощи такого оборудования, а также знаний и умений, мы
              постараемся помочь малышу!
            </p>
            <p>
              Ряд признаков, когда необходимо обратиться к врачу-неонатологу:
            </p>
            <ul>
              <li>1. Постоянное скуление (более 20 мин)</li>

              <li>2. Слизистые оболочки бледные, серые или синюшные.</li>

              <li>
                3. Неспособность сосать молоко и оставаться с однопомётниками.
              </li>
              <li>4. Диарея.</li>
              <li>5. Вздутый или спавшийся животик.</li>
              <li>6. Потеря веса или неспособность набирать вес.</li>
            </ul>
          </div>
        </>
      );
    }
    if (about == "Травматология и ортопедия") {
      return (
        <>
          <div className="CenterBlock">
            <p>
              Ортопедия – раздел ветеринарной медицины, изучающий профилактику,
              диагностику и лечение деформаций и нарушений костно-мышечной
              системы, которые являются результатом последствий травм,
              врожденных дефектов и заболеваний. Ортопедия теснейшим образом
              связанна с травматологией, а также с реабилитацией.{" "}
            </p>
            <p>
              В нашем Центре проводится широкий спектр ортопедических
              обследований и операций, начиная с осмотра ветеринарного ортопеда
              и заканчивая сложными операциями на позвоночнике.
            </p>
          </div>
          {/* <div className='CenterBlock CenterFill'>
                        <p>В нашем Центре проводится интенсивная терапия новорожденных животных, имеющих проблемы с нормальным материнским вскармливанием. </p>
                        <p>Ответ очевиден: нечасто.</p>
                    </div> */}
          {/* <div className='CenterBlock'>
                        
                    </div> */}
        </>
      );
    }

    if (about == "Родильное отделение и отделение неонатологии") {
      return (
        <>
          <div className="CenterBlock">
            <p>
              Специалисты нашего Центра одними из первых в России начали
              заниматься проблемами новорожденных собак и кошек. К настоящему
              времени накоплена большая база теоретических и практических знаний
              в области неонатологии животных.
            </p>
          </div>
          <div className="CenterBlock CenterFill">
            <p>
              В нашем Центре проводится интенсивная терапия новорожденных
              животных, имеющих проблемы с нормальным материнским
              вскармливанием.{" "}
            </p>
            {/* <p>Ответ очевиден: нечасто.</p> */}
          </div>
          <div className="CenterBlock">
            <p>
              Если малыш вялый, не сосет или плохо сосет маму, не прибавляет в
              весе, есть возможность привезти его к нам, в отделение
              неонатологии, где есть специальное оборудование для интенсивной
              терапии новорожденных (инкубаторы-брудеры) с поддержанием заданной
              влажности, температуры, ионизацией воздуха, оксигенотерапией. При
              помощи такого оборудования, а также знаний и умений, мы
              постараемся помочь малышу!
            </p>
            <p>
              Ряд признаков, когда необходимо обратиться к врачу-неонатологу:
            </p>
            <ul>
              <li>1. Постоянное скуление (более 20 мин)</li>

              <li>2. Слизистые оболочки бледные, серые или синюшные.</li>

              <li>
                3. Неспособность сосать молоко и оставаться с однопомётниками.
              </li>
              <li>4. Диарея.</li>
              <li>5. Вздутый или спавшийся животик.</li>
              <li>6. Потеря веса или неспособность набирать вес.</li>
            </ul>
          </div>
        </>
      );
    }
    if (about == "Эндоскопия и эндохирургия") {
      return (
        <>
          <div className="CenterBlock">
            <p>
              Эндоскопия – это область медицины, позволяющая с помощью
              специальных инструментов осматривать органы и полости животных, а
              также проводить манипуляции с диагностическими и лечебными целями.
            </p>
          </div>
          <div className="CenterBlock CenterFill">
            <p>
              Данное направление появилось в ветеринарии относительно недавно и,
              пока не все клиники имеют возможность проведения данных процедур.
            </p>
            {/* <p>Ответ очевиден: нечасто.</p> */}
          </div>
          <div className="CenterBlock">
            <p>
              С появлением эндоскопии значительно расширились диагностические
              возможности, так как при помощи данной процедуры можно тщательно
              осмотреть исследуемый орган, а также взять ткани (биопсию) на
              исследование для постановки более точного диагноза. Важно, что
              биопсия в этом случае проводится под контролем зрения, что
              позволяет более точно провести забор материала на интересующем
              участке.
            </p>
            <p>
              Эндоскопия позволяет выявить заболевания на ранних этапах
              развития, что особенно важно при постановке онкологических
              диагнозов. В настоящее время эндоскопические методы исследования
              используются как для диагностики, так и для лечения.
            </p>
          </div>
          <div className="CenterBlock">
            <p className="aboutBold">
              Виды эндоскопии, проводимой у нас в Центре
            </p>
            <ul>
              <li>
                • эзофагогастродуоденоскопия — осмотр пищевода, полости желудка
                и двенадцатиперстной кишки{" "}
              </li>
              <li>• ларингоскопия – осмотр глотки</li>
              <li>• гастроскопия — осмотр желудка</li>
              <li>• бронхоскопия — осмотр бронхов</li>
              <li>• лапароскопия — осмотр брюшной полости</li>
              <li>• риноскопия – осмотр носовых раковин</li>
              <li>• вагиноскопия – осмотр влагалища</li>
              <li>
                • отоскопия — наружного слухового прохода и барабанной
                перепонки;
              </li>
              <li>• цистоскопия — мочевого пузыря</li>
            </ul>
            <p>
              Эндоскопия в последнее время получила очень широкое применение в
              ветеринарии. Данная процедура позволяет проводить оперативные
              вмешательства с минимальной травматичностью и через небольшие
              проколы, что очень важно для наших маленьких пациентов. Кроме
              этого, она может осуществляться и с диагностической целью (в том
              числе для взятия биопсийного материала).{" "}
            </p>
            <br></br>
            <p className="aboutBold">Достоинства лапароскопических операций</p>
            <p>
              <ul>
                <li>• минимальная травматичность;</li>
                <li>• снижение риска послеоперационных осложнений;</li>
                <li>• значительное уменьшение реабилитационного периода;</li>
                <li>• отсутствие швов;</li>
                <li>
                  • нет необходимости в послеоперационном уходе и ношении
                  попоны.
                </li>
              </ul>
            </p>
          </div>
          <div className="CenterBlock">
            <p className="aboutBold">Проведение эндоскопии</p>
            <p>
              Эндоскопическое исследование животного проводится под общей
              седацией, поэтому питомец не испытывает никакого дискомфорта.
              Перед проведением эндоскопии важно подготовиться к процедуре –
              исключить прием пищи за 8 часов до исследования.{" "}
            </p>
            <p>
              Для проведения исследования мы применяем, как правило комбинацию
              Пропофол + Изофлуран (ингаляционная или газовая анестезия).
            </p>
            <p>
              После проведения обследования или эндоскопического хирургического
              вмешательства животное остается в клинике под присмотром
              анестезиолога-реаниматолога до полного пробуждения животного,
              после чего отдается владельцам.{" "}
            </p>
            <p>
              Исследование является абсолютно безопасным и безболезненным ввиду
              использования наркоза, а достоверность диагностики составляет
              почти 100%.
            </p>
            <p>
              Цена эндоскопии зависит от вида исследования и необходимости
              проведения дополнительных исследований.
            </p>
            <p>Лапароскопическая стерилизация собак и кошек</p>
            <p>
              С подробной информацией о проведении эндоскопической стерилизации
              собак и кошек Вы можете ознакомиться на нашем сайте, посвященном
              эндоскопической стерилизации:<br></br>
              <a href="https://www.endopet.ru/">www.endopet.ru </a>
            </p>
          </div>
        </>
      );
    }
    if (about == "Ультразвуковое исследование") {
      return (
        <>
          <div className="CenterBlock">
            <p>
              Ультразвуковая диагностика животных является одним из самых
              эффективных визуальных методов обследования внутренних органов и
              тканей животного с помощью ультразвуковых волн высокой частоты.
            </p>
            <p>
              Важно понимать, что данные ультразвукового обследования показывают
              структурные, анатомические изменения в органах и тканях животного,
              связанного с изменением эхоплотности тканей, но не показывают
              функциональную составляющую, для оценки которой необходимы
              исследования крови.{" "}
            </p>
          </div>
          <div className="CenterBlock CenterFill">
            <p>
              В нашем Центре установлен ультразвуковой аппарат высокого класса,
              оснащенный тремя датчиками для различных исследований, позволяющий
              выявить нарушения в структуре внутренних органов.
            </p>
            {/* <p>Ответ очевиден: нечасто.</p> */}
          </div>
          <div className="CenterBlock">
            <p className="aboutBold">
              Признаки заболевания животного, при которых животному может быть
              показана УЗ-диагностика, включают в себя:
            </p>
            <ul>
              <li>• потеря аппетита или его отсутствие;</li>
              <li>
                • частая, непрекращающаяся рвота (более 2-х раз в течение 8
                часов);
              </li>
              <li>• повышение температуры тела;</li>
              <li>
                • аномальная консистенция фекалий (частый жидкий стул),
                отсутствие актов дефекации;
              </li>
              <li>
                • аномальный цвет (или консистенция) мочи, отсутствие актов
                мочеиспускания, мочеиспускание малыми порциями (диурез);
              </li>
              <li>• кровь в моче (гематурия) или в фекалиях животного;</li>
              <li>
                • аномальный цвет или консистенция молока у животных,
                находящихся на вскармливании потомства;
              </li>
              <li>• частое прерывистое дыхание;</li>
              <li>• выделения из области половых органов, ануса;</li>
              <li>
                • беспокойное или чрезмерно апатичное поведение животного,
                несвойственное ему;
              </li>
              <li>• болезненность при прикосновении к животу животного.</li>
            </ul>
          </div>
        </>
      );
    }
  }
  return (
    <div className="ServicePage">
      <HeaderBanner headTitle={about} />
      <div className="ServiceBlock">
        <div className="LeftSection">
          {/* <p className="LeftTitle">Описание раздела</p>
          <p className="LeftSubTitle">Какая то надпись</p> */}
        </div>

        <div className="CenterSection">
          {RenderBlock()}

          {data ? (
            <PriceTable services={data[about]}></PriceTable>
          ) : (
            <p>loading..</p>
          )}
        </div>
      </div>
      <div>
        <EnrollComponent></EnrollComponent>
      </div>
    </div>
  );
};

export default ServicePage;
